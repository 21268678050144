/**
 * @license
 *
 * Font Family: Amulya
 * Designed by: Joana Correia
 * URL: https://www.fontshare.com/fonts/amulya/amulya
 * © 2024 Indian Type Foundry
 *
 * Font Styles:
 * Amulya Variable(Variable font)
 * Amulya Variable Italic(Variable font)
 * Amulya Light
 * Amulya Light Italic
 * Amulya Regular
 * Amulya Italic
 * Amulya Medium
 * Amulya Medium Italic
 * Amulya Bold
 * Amulya Bold Italic
 *
*/

/**
* This is a variable font
* You can controll variable axes as shown below:
* font-variation-settings: 'wght' 300.0;
*
* available axes:

* 'wght' (range from 300.0 to 700.0)

*/

@font-face {
    font-family: "Amulya-Variable";
    src: url("../fonts/amulya/Amulya-Variable.woff2") format("woff2"),
        url("../fonts/amulya/Amulya-Variable.woff") format("woff"),
        url("../fonts/amulya/Amulya-Variable.ttf") format("truetype");
    font-weight: 300 700;
    font-display: swap;
    font-style: normal;
}

/**
* This is a variable font
* You can controll variable axes as shown below:
* font-variation-settings: 'wght' 300.0;
*
* available axes:

* 'wght' (range from 300.0 to 700.0)

*/

@font-face {
    font-family: "Amulya-VariableItalic";
    src: url("../fonts/amulya/Amulya-VariableItalic.woff2") format("woff2"),
        url("../fonts/amulya/Amulya-VariableItalic.woff") format("woff"),
        url("../fonts/amulya/Amulya-VariableItalic.ttf") format("truetype");
    font-weight: 300 700;
    font-display: swap;
    font-style: italic;
}

@font-face {
    font-family: "Amulya-Light";
    src: url("../fonts/amulya/Amulya-Light.woff2") format("woff2"),
        url("../fonts/amulya/Amulya-Light.woff") format("woff"),
        url("../fonts/amulya/Amulya-Light.ttf") format("truetype");
    font-weight: 300;
    font-display: swap;
    font-style: normal;
}

@font-face {
    font-family: "Amulya-LightItalic";
    src: url("../fonts/amulya/Amulya-LightItalic.woff2") format("woff2"),
        url("../fonts/amulya/Amulya-LightItalic.woff") format("woff"),
        url("../fonts/amulya/Amulya-LightItalic.ttf") format("truetype");
    font-weight: 300;
    font-display: swap;
    font-style: italic;
}

@font-face {
    font-family: "Amulya-Regular";
    src: url("../fonts/amulya/Amulya-Regular.woff2") format("woff2"),
        url("../fonts/amulya/Amulya-Regular.woff") format("woff"),
        url("../fonts/amulya/Amulya-Regular.ttf") format("truetype");
    font-weight: 400;
    font-display: swap;
    font-style: normal;
}

@font-face {
    font-family: "Amulya-Italic";
    src: url("../fonts/amulya/Amulya-Italic.woff2") format("woff2"),
        url("../fonts/amulya/Amulya-Italic.woff") format("woff"),
        url("../fonts/amulya/Amulya-Italic.ttf") format("truetype");
    font-weight: 400;
    font-display: swap;
    font-style: italic;
}

@font-face {
    font-family: "Amulya-Medium";
    src: url("../fonts/amulya/Amulya-Medium.woff2") format("woff2"),
        url("../fonts/amulya/Amulya-Medium.woff") format("woff"),
        url("../fonts/amulya/Amulya-Medium.ttf") format("truetype");
    font-weight: 500;
    font-display: swap;
    font-style: normal;
}

@font-face {
    font-family: "Amulya-MediumItalic";
    src: url("../fonts/amulya/Amulya-MediumItalic.woff2") format("woff2"),
        url("../fonts/amulya/Amulya-MediumItalic.woff") format("woff"),
        url("../fonts/amulya/Amulya-MediumItalic.ttf") format("truetype");
    font-weight: 500;
    font-display: swap;
    font-style: italic;
}

@font-face {
    font-family: "Amulya-Bold";
    src: url("../fonts/amulya/Amulya-Bold.woff2") format("woff2"),
        url("../fonts/amulya/Amulya-Bold.woff") format("woff"),
        url("../fonts/amulya/Amulya-Bold.ttf") format("truetype");
    font-weight: 700;
    font-display: swap;
    font-style: normal;
}

@font-face {
    font-family: "Amulya-BoldItalic";
    src: url("../fonts/amulya/Amulya-BoldItalic.woff2") format("woff2"),
        url("../fonts/amulya/Amulya-BoldItalic.woff") format("woff"),
        url("../fonts/amulya/Amulya-BoldItalic.ttf") format("truetype");
    font-weight: 700;
    font-display: swap;
    font-style: italic;
}

body {
    font-family: "Amulya-Regular", sans-serif;
}
